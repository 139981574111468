button[disabled] {
  @apply opacity-35 pointer-events-none;
}

.btn {
  @apply font-bold text-sm py-0.5 px-6 rounded-full uppercase transition-transform;

  &:hover,
  &:focus {
    @apply transform scale-105;
  }

  &.btn-primary-light {
    @apply bg-white text-black;
  }

  &.btn-primary-dark {
    @apply bg-black text-white;
  }

  &.btn-primary-success {
    @apply bg-green text-white;
  }

  &.btn-primary-error {
    @apply bg-garnet text-white;
  }
}
