:root {
  --reach-tooltip: 1;
  --reach-dialog: 1;
}

[data-reach-tooltip] {
  @apply bg-black z-20 pointer-events-none absolute text-xs text-white p-2 max-w-max rounded-md;
}

[data-reach-dialog-overlay] {
  @apply z-20 fixed top-0 right-0 left-0 bottom-0 overflow-auto flex justify-center items-center bg-darkGray-1000 bg-opacity-85;
}

[data-reach-dialog-content] {
  @apply outline-none;
}
