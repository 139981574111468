@import './buttons.css';
@import './inputs.css';
@import './lib.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body:not(.user-is-tabbing) *:focus {
  @apply outline-none;
}

.funding-currency-ribbon {
  @apply relative;

  &::after {
    content: '';
    @apply absolute top-0 left-0 transform -translate-x-0.5 translate-y-0.5 bg-transparent rounded border border-solid border-yellow w-32 h-6 cursor-default;
  }
}

.will-change-height {
  will-change: height;
}

.will-change-transform {
  will-change: transform;
}

.will-change-opacity {
  will-change: opacity;
}

.will-change-width {
  will-change: width;
}

.border-spacing-y {
  border-spacing: 0 1px;
}
