input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    @apply appearance-none m-0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &[type='radio'] {
    @apply inline-block w-3 h-3 border border-white border-opacity-50 rounded-full appearance-none bg-clip-content p-px checked:bg-white;
  }
}

.input {
  @apply rounded font-medium text-sm text-center;

  &.input-primary-dark {
    @apply bg-darkGray-900 text-white placeholder-white placeholder-opacity-50;

    &[readonly] {
      @apply text-white text-opacity-60 placeholder-white placeholder-opacity-25;
    }
  }

  &.input-error {
    @apply bg-mahogany;
  }

  &.input-number-negative {
    @apply text-red;

    &[readonly] {
      @apply text-red text-opacity-60;
    }
  }

  &.input-number-positive {
    @apply text-green;

    &[readonly] {
      @apply text-green text-opacity-60;
    }
  }
}
